/**
 * Plugin Functionality for Cookie Consent.
 * https://github.com/orestbida/cookieconsent
 */


let cookieConsent, company, contact, policy, path, consent;

if (typeof initCookieConsent !== "undefined") {
    cookieConsent = initCookieConsent();
    company = "Military Veteran Pipeline, LLC";
    contact = "https://militaryveteranpipeline.com/contact";
    policy = "https://militaryveteranpipeline.com/privacy-policy";
    path = location.pathname;
    consent = true;
}

function containsAny(str, lookup) {
    for (let i = 0; i !== lookup.length; i++) {
        const string_value = lookup[i];
        if (str.indexOf(string_value) !== -1) {
            return true;
        }
    }
    return false;
}

if (containsAny(path, ["contact", "terms-and-conditions", "privacy-policy"])) {
    consent = false;
}

// initialize plugin and set preferences
if (typeof cookieConsent !== "undefined") {
    let cookie_domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];

    if (cookie_domain === 'localhost') {
        cookie_domain = null;
    }

    cookieConsent.run({
        // flag for revision of cookie handling
        revision: 11,

        // cookie Base Setup
        autorun: true,
        current_lang: "en",
        autoclear_cookies: true,
        cookie_domain: cookie_domain,
        force_consent: consent,
        cookie_name: "mvp_cookie",
        cookie_expiration: 365, // Enter in number of days until cookie expires.
        use_rfc_cookie: true,
        page_scripts: true,

        // styling
        gui_options: {
            consent_modal: {
                layout: "bar",
                position: "bottom",
                transition: "slide",
                swap_buttons: false,
            },
            settings_modal: {
                layout: "box",
                transition: "slide",
            },
        },

        // modal options
        languages: {
            en: {
                consent_modal: {
                    description: `
                        <p>${company} and selected third parties use cookies or similar technologies to provide you with the best user experience possible and, with your consent, for other purposes as specified in our <a href="${policy}" class="cc-link" target="_blank">privacy policy</a>.</p>
                        <div class="cc-buttons">
                            <button type="button" data-cc="accept-all" class="link-btn link-blue">Accept All</button>
                            <button type="button" data-cc="accept-necessary" class="link-btn link-red">Reject All</button>
                            <button type="button" data-cc="c-settings" class="link-btn link-blue-outline">Manage Preferences</button>
                        </div>
                    `,
                    revision_message: "Terms and Settings may have changed.",
                },
                settings_modal: {
                    title: "Cookie Preferences",
                    save_settings_btn: "Save Preferences",
                    accept_all_btn: "Accept All",
                    reject_all_btn: "Reject All",
                    close_btn_label: "Close",
                    blocks: [
                        {
                            description: `Military Veteran Pipeline, LLC uses cookies to provide basic functionality for the website and enhance your overall user experience. You can opt-in/out of certain cookies by selecting the toggle switch to the right of the cookie below. For more details related to cookies and other sensitive data, please read the full <a href="${policy}" target="_blank">privacy policy</a>.`,
                        },
                        {
                            title: "Tracking and Performance Cookies",
                            description: `These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new pages, features or new functionality of the Website to see how our users react to them.`,
                            toggle: {
                                value: "analytics",
                                enabled: true,
                                readonly: false,
                            }
                        },
                        {
                            title: "Functionality Cookies",
                            description: `These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.`,
                            toggle: {
                                value: "mvp_website",
                                enabled: true,
                                readonly: false,
                            }
                        },
                        {
                            title: "More Information",
                            description: 'For any queries in relation to our policy on cookies and your choices, please <a href="' + contact + '">contact us</a>.',
                        },
                    ],
                },
            },
        },
    });
}