$('.testimonial-slider .testimonials').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 1000,
    infinite: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 7000,
    arrows : true,
    pauseOnFocus: true,
    adaptiveHeight: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 540,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false
            }
        }
    ],
    prevArrow: '<button type="button" class="slick-prev" title="Previous Testimonial"><i class="fa-solid fa-angle-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next" title="Next Testimonial"><i class="fa-solid fa-angle-right"></i></button>'
});