$('.search-download-btn, #profile-download-btn').on('click',function(){
    let download = $(this).data('resume-download');
    if(download === true){
      document.getElementById('modal_resume_sku').value = $(this).data('resume-sku');
      $("#download-resume-modal").modal('show');
    } else if(download === 'active'){
      $("#active-resume-modal").modal('show');
    } else {
      $("#upgrade-resume-modal").modal('show');
    }
});

let url = location.href;
if (url) {
    const lastSegment = url.split("/").pop();
    if(lastSegment === 'cancel_complete'){
        $('#success_cancel_subscribe').modal('show');
    }
}

$("#success_cancel_subscribe").on("hidden.bs.modal", function () {
  let path = location.protocol + '//' + location.host;
  window.location = path + "/my-mvp/account-details";
});