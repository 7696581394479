/**
 * This library handles repeatable fields in forms, so that you may add or
 * remove as many chunks of form inputs as needed.
 *
 * Layout Hint:
 *
 * <form>
 *   <div data-repeatable-name="my-repeatable">
 *     <div data-repeatable-item>
 *       I will become repeatable!
 *       <button data-repeatable-action="remove-my-repeatable">Remove Me</button>
 *     </div>
 *   </div>
 *   <button data-repeatable-action="add-my-repeatable">Add A duplicate</button>
 * </form>
 */

(() => {
    "use strict";

    const lib = {};

    /**
     * looks through the dom to attach repeatable field behavior to any element that
     * has not yet been processed for it.
     */
    lib.register = () => {
        const list = document.querySelectorAll("[data-repeatable-name]");
        list.forEach(($element) => {
            const $container = $element;
            const $template = $container.querySelector("[data-repeatable-template]");

            const $parent =
                $container.closest("[data-repeatable-wrapper]") ||
                $container.closest("form") ||
                document;

            // we require these
            if (!$container || !$parent || !$template) {
                return;
            }

            if (!$element.dataset.repeatableActive) {
                // we flag the element to ensure it isn't re-processed if we call this
                // method again
                $element.dataset.repeatableActive = "true";

                lib.registerRepeatableClickHandlers($parent, $container, $template);
                lib.indexRepeatableFields($parent, $container, $template);
            }
        });
    };

    /**
     * Registers click handlers for data-repeatable-action bound elements
     * @param $parent HTMLElement the form element that will act as the parent
     * @param $container HTMLElement the container element that holds the items
     * @param $template HTMLElement the base item for cloning
     */
    lib.registerRepeatableClickHandlers = ($parent, $container, $template) => {
        if ($parent.dataset.fuseactive !== "true") {
            $parent.dataset.fuseactive = "true";
            ['click'].forEach(event_type => {
                $parent.addEventListener(event_type, (event) => {
                    // we care about data-repeatable-action elements, even if we clicked
                    // something inside them (like an icon within a button we care about)
                    const $action_element = event.target.dataset.repeatableAction
                        ? event.target
                        : event.target.closest("[data-repeatable-action]");

                    if (!$action_element) {
                        return;
                    }

                    event.preventDefault();
                    event.stopPropagation();

                    // re-calculate our label container and parent from this element - needed
                    // to handle nested repeatable elements
                    const label = $action_element.dataset.repeatableAction
                        .replace("add-", "")
                        .replace("remove-", "");
                    $parent =
                        $action_element.closest("[data-repeatable-wrapper]") ||
                        $action_element.closest("form") ||
                        document;
                    $container = $parent.querySelector(`[data-repeatable-name="${label}"]`);
                    $template = $parent.querySelector("[data-repeatable-template]");

                    let item_count = 0;
                    $container
                        .querySelectorAll("[data-repeatable-item]")
                        .forEach(($item) => {
                            const $item_parent =
                                $item.closest("[data-repeatable-wrapper]") ||
                                $item.closest("form") ||
                                document;

                            $item_parent === $parent && item_count++;
                        });

                    switch ($action_element.dataset.repeatableAction) {
                      case `add-${label}`:
                            const $clone = $template.cloneNode(true);

                            document.dispatchEvent(
                                new CustomEvent("data-repeatable-adding", {
                                    detail: {
                                        label: label,
                                        parent: $parent,
                                        container: $container,
                                        template: $template,
                                        element: $clone,
                                    },
                                })
                            );

                            if ($clone && !$clone.preventRender) {
                                delete $clone.dataset.repeatableTemplate;
                                $clone.setAttribute("data-repeatable-item", "");
                                $clone.querySelectorAll("[data-required]").forEach(($el) => {
                                    $el.setAttribute("required", "required");
                                    $el.removeAttribute("data-required");
                                });
                                $container.append($clone);

                                document.dispatchEvent(
                                    new CustomEvent("data-repeatable-added", {
                                        detail: {
                                            label: label,
                                            parent: $parent,
                                            container: $container,
                                            template: $template,
                                            element: $clone,
                                        },
                                    })
                                );
                                item_count++;
                            }

                            // initialize the data picker object
                            window.initDatePickers();
                            break;
                        case `remove-${label}`:
                            const $to_remove = $action_element.closest(
                                "[data-repeatable-item]"
                            );

                            document.dispatchEvent(
                                new CustomEvent("data-repeatable-removing", {
                                    detail: {
                                        label: label,
                                        parent: $parent,
                                        container: $container,
                                        template: $template,
                                        element: $to_remove,
                                    },
                                })
                            );

                            const keep_amount_string = $container.dataset.repeatableKeep || "0";
                            const keep_amount = parseInt(keep_amount_string);

                            if ($to_remove && item_count > keep_amount) {
                                $to_remove.remove();
                                item_count--;
                                document.dispatchEvent(
                                    new CustomEvent("data-repeatable-removed", {
                                        detail: {
                                            label: label,
                                            parent: $parent,
                                            container: $container,
                                            template: $template,
                                            element: $to_remove,
                                        },
                                    })
                                );
                            }

                            break;
                        default:
                            break;
                    }

                    // re-index all fields
                    lib.indexRepeatableFields($parent, $container, $template);

                    // show all remove buttons by default
                    $container
                        .querySelectorAll(`[data-repeatable-action=remove-${label}]`)
                        .forEach(($remove_button) => {
                            $remove_button.classList.remove("d-none");
                        });

                    // hide the remove button if there is only one element
                    // if (item_count === 1) {
                    //     $container
                    //         .querySelector(`[data-repeatable-action=remove-${label}]`)
                    //         .classList.add("d-none");
                    // }
                });
            });
        }
    };

    /**
     * takes in an attribute value and current index number, and returns the mutated
     * attribute value
     * @param prefix a string to add before the value
     * @param value the string attribute value that currently exists on the element
     * @param index the index value to use
     */
    lib.getIndexedAttributeValue = (prefix, value, index) => {
        let name_starts_at = 0;
        let name_ends_at = value.length;

        if (value.indexOf("__") !== -1) {
            name_starts_at = value.indexOf("__") + 2;
        }
        if (value.indexOf("--") !== -1) {
            name_ends_at = value.lastIndexOf("--");
        }
        const old_value = value.substring(name_starts_at, name_ends_at);

        if (prefix.length > 0) {
            return `${prefix}__${old_value}--${index}`;
        } else {
            return `${old_value}--${index}`;
        }
    };

    /**
     * re-indexes the name, id, and for values of all repeatable items. starts at 1
     * @param $parent HTMLElement the form element that will act as the parent
     * @param $container HTMLElement the container element that holds the items
     * @param $template HTMLElement the base item for cloning
     */
    lib.indexRepeatableFields = ($parent, $container, $template) => {
        const items = $container.querySelectorAll("[data-repeatable-item]");
        const wrappers = document.querySelectorAll("[data-repeatable-wrapper]");

        // create our recursive dom walker
        const walk = (node, func) => {
            let children = node.children;
            for (let i = 0; i < children.length; i++) {
                walk(children[i], func);
            }
            func(node);
        };

        let wrapper_index = -1;

        wrappers.forEach(($wrapper) => {
            wrapper_index++;
            const label = $wrapper.querySelector("[data-repeatable-name]").dataset
                .repeatableName;
            $wrapper.dataset.indexName = `${label}-${wrapper_index}`;
        });

        // index all items (templates will fill 0)
        let item_index = 0;
        items.forEach(($template) => {
            item_index++;
            walk($template, ($node) => {
                const $wrapper = $node.closest("[data-repeatable-wrapper]");
                let prefix = "";
                if ($wrapper) {
                    prefix = $wrapper.dataset.indexName;
                }

                ["name", "for", "id"].forEach((attribute_name) => {
                    if ($node.hasAttribute(attribute_name)) {
                        const current_value = $node.getAttribute(attribute_name);
                        $node.setAttribute(
                            attribute_name,
                            lib.getIndexedAttributeValue(prefix, current_value, item_index)
                        );
                    }
                });
            });
        });
    };

    lib.domReady = (callback) => {
        function handler() {
            document.removeEventListener('DOMContentLoaded', handler, false)
            window.removeEventListener('load', handler, false)
            callback() // may pass parent context here
        }

        document.addEventListener('DOMContentLoaded', handler, false)
        window.addEventListener('load', handler, false)
    }

    // attach to window and auto-register
    window.RepeatableFields = lib;
    lib.domReady(() => {
        window.RepeatableFields.register();
    });
})();
