/**
 * author: shane@integratedwebworks.com
 * overrides the form submission process, which bundles form data by looking for
 * data-save-list and data-save-as attributes
 *
 * we use this as it is much easier to send explicit data when dealing with
 * repeatable and dynamic form fields with intricate nesting.
 *
 * data-save-as : when applied to an element, the element's value will be
 * saved to the location provided. Example:
 *
 *      <input type="text" value="Todd" data-save-as="user.first_name"/>
 *
 *      {
 *        user: {
 *          first_name: "Todd";
 *        }
 *      }
 *
 * data-save-list : this is applied to a parent element. any descendant
 * data-save-as inputs will be considered as part of a list item. Example:
 *
 *      <div data-save-list="gift_basket.fruit">
 *        <input type="text" value="red" data-save-as="color"/>
 *        <input type="text" count="4" data-save-as="count"/>
 *      </div>
 *      <div data-save-list="gift_basket.fruit">
 *        <input type="text" value="yellow" data-save-as="color"/>
 *        <input type="text" count="2" data-save-as="count"/>
 *      </div>
 *
 *      {
 *        gift_basket: {
 *          fruit: [
 *            {color:"red",count:"4"},
 *            {color:"yellow",count:"2"},
 *          ]
 *        }
 *      }
 */
(() => {
  "use strict";
  const lib = {};

  /**
   * adds a value to the object being constructed at any depth
   * @param obj the object to set values on
   * @param path the string representation of the value location some.nested.val
   * @param val the value to set
   * @param is_list
   */
  lib.setNestedObjectValue = (obj, path, val, is_list) => {
    const keys = path.split(".");
    const lastKey = keys.pop();
    const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
    if (is_list) {
      if (!lastObj[lastKey]) {
        lastObj[lastKey] = [];
      }
    } else {
      lastObj[lastKey] = val;
    }
  };

  /**
   * adds a list entry to the object being constructed at any depth
   * @param obj the object to set values on
   * @param path the string representation of the value location some.nested.val
   * @param val the value to set
   */
  lib.appendNestedObjectArray = (obj, path, val) => {
    const keys = path.split(".");
    const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
    lastObj.push(val);
  };

  /**
   * checks if an element is a radio input
   * @param $element the HTMLelement to check
   * @returns {boolean} returns true if the element is a radio input
   */
  lib.isRadio = ($element) => {
    return (
      $element.getAttribute("type") &&
      $element.getAttribute("type").toLowerCase() === "radio"
    );
  };

  /**
   * checks if an element is a checkbox input
   * @param $element the HTMLelement to check
   * @returns {boolean} returns true if the element is a checkbox input
   */
  lib.isCheckbox = ($element) => {
    return (
      $element.getAttribute("type") &&
      $element.getAttribute("type").toLowerCase() === "checkbox"
    );
  };

  /**
   * gets the selected radio button's value
   * @param $parent the parent element to look in
   * @param $element the radio element in question
   */
  lib.getRadioValue = ($parent, $element) => {
    const $selected_radio = $parent.querySelector(
      `[name="${$element.getAttribute("name")}"]:checked`
    );
    if ($selected_radio && $selected_radio.value) {
      return $selected_radio.value;
    } else {
      return null;
    }
  };

  /**
   * gets the selected checkbox value in 1 or 0
   * @param $element the checkbox element in question
   */
  lib.getCheckboxValue = ($element) => {
    return $element.checked ? 1 : 0;
  };

  lib.build = {};
  /**
   * constructs arrays at any depth level in the referenced object
   * @param $form the form element
   * @param obj the object to mutate
   */
  lib.build.lists = ($form, obj) => {
    // save all lists...
    $form.querySelectorAll("[data-save-list]").forEach(($element) => {
      if ($element.closest("[data-repeatable-template]")) {
        // ...but not if this is a part of a template
        return;
      }
      lib.setNestedObjectValue(obj, $element.dataset.saveList, [], true);

      // collect all descendant save-as values into a list item
      let list_item = {};
      $element.querySelectorAll("[data-save-as]").forEach(($descendant) => {
        let value;
        if (lib.isRadio($descendant)) {
          value = lib.getRadioValue(
            $form.querySelectorAll("[data-save-list]"),
            $descendant
          );
        } else if (lib.isCheckbox($descendant)) {
          value = lib.getCheckboxValue($descendant);
        } else {
          value = $descendant.value;
        }

        list_item[$descendant.dataset.saveAs] = value;
      });

      // append the list item to the object
      lib.appendNestedObjectArray(obj, $element.dataset.saveList, list_item);
    });
  };
  /**
   * constructs values at any depth level in the referenced object
   * @param $form the form element
   * @param obj the object to mutate
   */
  lib.build.values = ($form, obj) => {
    $form.querySelectorAll("[data-save-as]").forEach(($element) => {
      // save all values...
      if ($element.closest("[data-repeatable-template]")) {
        // ...but not if this is part of a template
        return;
      }
      if (!$element.closest("[data-save-list]")) {
        let value;
        if (lib.isRadio($element)) {
          value = lib.getRadioValue($form, $element);
        } else if (lib.isCheckbox($element)) {
          value = lib.getCheckboxValue($element);
        } else {
          value = $element.value;
        }
        lib.setNestedObjectValue(obj, $element.dataset.saveAs, value);
      }
    });
  };

  /**
   * checks if an object is a function
   * @param obj the object to test
   */
  lib.isFunction = (obj) => {
    return !!(obj && obj.constructor && obj.call && obj.apply);
  };

  /**
   * gets the action endpoint of the form
   * @param $form
   * @returns {string}
   */
  lib.getFormAction = ($form) => {
    let action = $form.getAttribute("action");
    if (!action || action === "") {
      // defaults to the current page
      action = "//" + location.host + location.pathname;
    }
    return action;
  };

  /**
   * gets the method the form
   * @param $form
   * @returns {string}
   */
  lib.getFormMethod = ($form) => {
    let method = $form.getAttribute("method");
    if (!method || method === "GET") {
      method = "./"; // defaults to GET
    }
    return method;
  };

  /**
   * checks the return type of callback to determine if the form can
   * be submitted
   * @param callback the function to run before submitting. if the function
   * returns false, we return false to indicate not to submit the form
   * @param payload the constructed object of values
   */
  lib.beforeSubmitCheck = (callback, payload) => {
    if (lib.isFunction(callback)) {
      const return_value = callback(payload);
      return return_value !== false;
    } else {
      return true;
    }
  };

  /**
   * builds out an object from form fields, using data-save-list
   * and data-save-as attributes
   * @param $form
   */
  lib.buildObjectFromInputs = ($form) => {
    let obj = {};
    lib.build.lists($form, obj);
    lib.build.values($form, obj);
    return obj;
  };

  /**
   * submits form data as a payload value, calling callbacks if necessary
   * @param $form
   * @param action the form action
   * @param method the form method
   * @param payload the form payload
   * @param onResponse function to call on response
   * @param onError function to call on error
   */
  lib.submitFormData = (
    $form,
    action,
    method,
    payload,
    onResponse,
    onError
  ) => {
    let formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    // get all file uploads
    $form.querySelectorAll('input[type="file"]').forEach(($file) => {
      if ($file.files && $file.files.length > 0) {
        formData.append("file", $file.files[0]);
      }
    });
    // alert('here');
    fetch(action, {
      method: method,
      body: formData,
    })
      .then((response) => {
        // ensure our response is properly handled in case of text return
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json();
        } else {
          return response.text();
        }
      })
      .then((data) => {
        // we have response data
        lib.isFunction(onResponse) && onResponse(data);
      })
      .catch((err) => {
        // we have an error
        lib.isFunction(onError) && onError(err);
      });
  };

  /**
   * registers a form to use json mode, which compiles a payload from
   * form data. You can also optionally provide three callbacks
   * @param $form the form element to register
   * @param beforeSubmit called before submitting the form. return false to cancel submission
   * @param onResponse called when a successful response is returned with data passed as the argument
   * @param onError called when an error is encountered
   */
  lib.register = ($form, beforeSubmit, onResponse, onError) => {
    if (!$form) {
      return;
    }

    $form.addEventListener("submit", (event) => {
      event.preventDefault();

      let action = lib.getFormAction($form);
      let method = lib.getFormMethod($form);
      const payload = lib.buildObjectFromInputs($form);

      const can_submit = lib.beforeSubmitCheck(beforeSubmit, payload);
      if (!can_submit) {
        return;
      }
      lib.submitFormData($form, action, method, payload, onResponse, onError);
    });
  };

  window.json_form = window.json_form || lib;
})();
