// import date picker
// datepicker documentation: https://mymth.github.io/vanillajs-datepicker/
import { Datepicker } from 'vanillajs-datepicker';

window.initDatePickers = () => {
    document.querySelectorAll('[data-datepicker]').forEach((element) => {
        let min_date = element.getAttribute('data-datepicker-min-date');
        let max_date = element.getAttribute('data-datepicker-max-date');
        let datepicker_initialized = element.getAttribute('data-datepicker-initialized');

        element.addEventListener('click', (event) => {
            event.preventDefault();
        });

        if (datepicker_initialized === "true") {
            return;
        }

        // set initialized attribute to prevent rerunning the logic.
        element.setAttribute('data-datepicker-initialized', 'true');

        new Datepicker(element, {
            // ...options
            'orientation': 'auto',
            'autohide': true,
            'todayHighlight': true,
            'updateOnBlur': true,
            'enableOnReadonly': true,
            'format': 'yyyy-mm-dd', // this is the format compatible with date fields
            'minDate': min_date,
            'maxDate': max_date,
            'nextArrow': '<i class="fa-solid fa-angles-right"></i>',
            'prevArrow': '<i class="fa-solid fa-angles-left"></i>',
        });
    });

    // set repeatable datepicker-initialized to false
    document.querySelectorAll('[data-repeatable-template] [data-datepicker]').forEach((element) => {
        element.setAttribute('data-datepicker-initialized', 'false');
    });
}

// prevent date field default calendar from displaying
document.querySelectorAll('[data-datepicker]').forEach((element) => {
    element.addEventListener('click', (event) => {
        event.preventDefault();
    });
});

// also call it immediately
document.addEventListener("DOMContentLoaded", (event) => {
    window.initDatePickers();
});
