// import stylesheets
import "@local/css/dataTables.bootstrap5.css";
import "@local/sass/main.scss";

// import Fuse library
import Fuse from 'fuse.js';
(<any>window).Fuse = Fuse;

// import files
import "@local/ts/rawjs/cookieconsent-init";
import "@local/ts/rawjs/iw";
import "@local/ts/rawjs/date-picker";
import "@local/ts/rawjs/slideshows";
import "@local/ts/rawjs/detect-card";
import "@local/ts/rawjs/form-repeatable-fields";
import "@local/ts/rawjs/form-custom-inputs";
import "@local/ts/rawjs/form-json-mode";
import "@local/ts/rawjs/form-signup";
import "@local/ts/rawjs/form-validation";
import "@local/ts/rawjs/detect-autofill";
import "@local/ts/rawjs/subscription";
import "@local/ts/rawjs/datatables-custom";
import "@local/ts/rawjs/script";