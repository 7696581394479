/**
 * This is a utility library to handle DOM manipulation in a streamlined way
 *
 * Author: Shane Stanley
 *         <shane@integratedwebworks.com>
 *         <shane.stanley1983@gmail.com>
 */

/**
 * Wraps one DOM element node inside another
 * @param el an html dom element you want to place in the container
 * @param wrapper an html dom element that acts as the container
 */
const wrap = (el, wrapper) => {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
};

/**
 * File Drop widget
 *
 * Author: Shane Stanley
 *         <shane@integratedwebworks.com>
 *         <shane.stanley1983@gmail.com>
 */
let el_base, el_container, el_content, el_icon, el_text;

/**
 * Toggles the container's disabled flag in sync with the input's
 */
const setAreaDisabledState = (el_base, el_container) => {
    // change disabled state
    let base_disabled = el_base.hasAttribute('disabled');

    if (base_disabled) {
        el_container.setAttribute('disabled', base_disabled);
    } else {
        el_container.removeAttribute('disabled');
    }
};

/**
 * Sets the widget to reflect when a file is loaded
 */
const setAreaLoaded = (el_base, el_container, el_icon, el_text, empty_icon, loaded_icon) => {
    el_container.classList.remove('iw-widget__filedrop--loading');
    el_container.classList.add('iw-widget__filedrop--loaded');
    el_container.classList.remove('iw-widget__filedrop--invalid');
    let newText = el_base.dataset.loadedText || '{file}';
    let display_file = el_base.value.replace(/^.*[\\\/]/, '');
    el_text.innerHTML = newText.replace('{file}', display_file);
    empty_icon && el_icon.classList.remove(...empty_icon.split(' '));
    loaded_icon && el_icon.classList.add(...loaded_icon.split(' '));
};

/**
 * Sets the widget to reflect when a file is invalid
 */
const setAreaInvalid = (el_base, el_container, el_icon, el_text, empty_icon, loaded_icon) => {
    el_container.classList.remove('iw-widget__filedrop--loading');
    el_container.classList.remove('iw-widget__filedrop--loaded');
    el_container.classList.add('iw-widget__filedrop--invalid');
    el_text.innerHTML = el_base.dataset.emptyText || 'Drop your file here';
    loaded_icon && el_icon.classList.remove(...loaded_icon.split(' '));
    empty_icon && el_icon.classList.add(...empty_icon.split(' '));
};

/**
 * Sets the widget to reflect the default state when no file is loaded
 */
const setAreaDefault = (el_base, el_container, el_icon, el_text, empty_icon, loaded_icon) => {
    el_container.classList.remove('iw-widget__filedrop--loaded');
    el_container.classList.remove('iw-widget__filedrop--loading');
    el_container.classList.remove('iw-widget__filedrop--invalid');
    el_text.innerHTML = el_base.dataset.emptyText || 'Drop your file here';
    loaded_icon && el_icon.classList.remove(...loaded_icon.split(' '));
    empty_icon && el_icon.classList.add(...empty_icon.split(' '));
};

/**
 * Builds the widget from a base dom element
 * @param el the html dom element that contains the iww-data-widget attribute
 */
const constructWidget = el => {
    // set the base element
    el_base = el; // adjust the base element

    el_base.setAttribute('class', `iw-widget__filedrop__input ${el_base.getAttribute('class')}`); // compose the needed elements

    el_container = document.createElement('div');
    el_container.setAttribute('class', 'iw-widget__filedrop');
    el_content = document.createElement('div');
    el_content.setAttribute('class', 'iw-widget__filedrop__content');
    el_icon = document.createElement('i');
    el_icon.setAttribute('class', `iw-widget__filedrop__content__icon ${el_base.dataset.emptyIcon || ''}`);
    el_text = document.createElement('span');
    el_text.setAttribute('class', 'iw-widget__filedrop__content__text');
    el_text.innerHTML = el_base.dataset.emptyText || 'Drop your file here'; // assemble the dom structure

    el_content.appendChild(el_icon);
    el_content.appendChild(el_text);
    el_container.appendChild(el_content);
    wrap(el_base, el_container);
    setAreaDisabledState(el_base, el_container);
};

/**
 * Attaches necessary event handlers
 */
const attachEvents = () => {
    el_base.addEventListener('change', function (event) {
        // capture required dom elements within the event
        el_base = event.currentTarget;
        el_container = el_base.closest('.iw-widget__filedrop');
        el_icon = el_container.querySelector('.iw-widget__filedrop__content__icon');
        el_text = el_container.querySelector('.iw-widget__filedrop__content__text');
        let empty_icon = el_base.dataset.emptyIcon;
        let loaded_icon = el_base.dataset.loadedIcon; // change appearance based on whether file input is filled or not
        let filename = el_base.value;
        let filesize = el_base?.files[0]?.size;
        let filename_parts = filename.split('.');
        let extension = '.' + filename_parts[filename_parts.length - 1];
        let max_filesize = '5000000'; // 5MB

        const upload_resume_button = document.getElementById("upload_resume_button"),
              complete_button = document.getElementById("complete");

        if (el_base.value === '') {
            setAreaDefault(el_base, el_container, el_icon, el_text, empty_icon, loaded_icon);
        } else if(!el_base.dataset.accepts.includes(extension) || filesize > max_filesize) {
            el_base.value = '';
            if (document.querySelector("[name=upload_resume]")) {
                (upload_resume_button) && (upload_resume_button.disabled = true);
                (complete_button) && (complete_button.disabled = true);
            }
            setAreaInvalid(el_base, el_container, el_icon, el_text, empty_icon, loaded_icon);
        } else {
            if (document.querySelector("[name=upload_resume]")) {
                (upload_resume_button) && (upload_resume_button.disabled = false);
                (complete_button) && (complete_button.disabled = false);
            }
            setAreaLoaded(el_base, el_container, el_icon, el_text, empty_icon, loaded_icon);
        }
    });
    el_base.addEventListener('dragover', event => {
        el_base = event.currentTarget;
        el_container = el_base.closest('.iw-widget__filedrop');
        el_container.classList.add(`iw-widget__filedrop--loading`);
    });
    el_base.addEventListener('dragleave', event => {
        el_base = event.currentTarget;
        el_container = el_base.closest('.iw-widget__filedrop');
        el_container.classList.remove(`iw-widget__filedrop--loading`);
    });
    el_base.addEventListener('drop', event => {
        el_base = event.currentTarget;
        el_container = el_base.closest('.iw-widget__filedrop');
        el_container.classList.remove(`iw-widget__filedrop--loading`);
    });
};

const filedrop = () => {
    return {
        name: 'filedrop',
        load: function (el) {
            constructWidget(el);
            attachEvents();
        },
        unload: function (el) {
            // if we plan to handle a lot of asynchronously loaded widgets
        }
    };
};

/**
 * Loads all widgets into an array for exporting to code
 *
 * Author: Shane Stanley
 *         <shane@integratedwebworks.com>
 *         <shane.stanley1983@gmail.com>
 */
const widgetList = [filedrop];

/**
 * Main js entry point. This will loadSubscription all widget logic and apply the logic to
 * matching elements on the page
 *
 * Author: Shane Stanley
 *         <shane@integratedwebworks.com>
 *         <shane.stanley1983@gmail.com>
 */

window.iw = window.iw || {};
window.iw.widgets = []; // initialize widget logic

widgetList.forEach(widget_generator => {
    let widget = widget_generator();
    window.iw.widgets[widget.name] = widget;
}); // scan the page for supported widgets and pass them into the matching logic
// handler

document.querySelectorAll('[data-iw-widget]').forEach(el => {
    let name = el.dataset.iwWidget;
    let loaded = el.dataset.iwLoaded;

    if (!loaded && name && window.iw.widgets[name] && typeof window.iw.widgets[name].load && typeof window.iw.widgets[name].load === 'function') {
        window.iw.widgets[name].load(el);
        el.dataset.iwLoaded = "true";
    }
});