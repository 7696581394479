(() => {
  /**
   * author: shane@integratedwebworks.com
   * handles the sign-up process, which bundles form data by looking for
   * data-save-list and data-save-as attributes
   */

  "use strict";

  const $save_and_exit_button = document.querySelector("[data-logout]");
  if ($save_and_exit_button) {
    document
      .querySelector("[data-logout]")
      .addEventListener("click", (event) => {
        const $form = event.currentTarget.closest("form");
        if ($form) {
          const $logout_input = $form.querySelector('[data-save-as="logout"]');
          if ($logout_input) {
            const $continue = $form.querySelector('button[type="submit"]');
            if ($continue) {
              $form.querySelectorAll('input, textarea').forEach(el => {
                el.removeAttribute('required');
              });
              $logout_input.value = "true";
              $continue.click();
            }
          }
        }
      });
  }

  const $signup_form = document.querySelector(".create-account-form");
  window.json_form.register(
    $signup_form,
    function beforeSubmit(payload) {
      const violations = window.validation.validate($signup_form);
      if (violations.length < 1) {
        return true;
      }
    },
    function onResponse(data) {
      undoFormProcessing($signup_form);
      if (data.error) {
        const $error_container = $signup_form.querySelector("#error_report");

        if (data.violations && $error_container) {
          let error_content;
          if (data.violations.constructor === Array) {
            error_content = data.violations.join("<br><br>");
          } else if (data.violations.constructor === Object) {
            error_content = "";
            Object.entries(data.violations).forEach(([, value], index) => {
              error_content += `${value}<br><br>`;
            });
          }
          $error_container.innerHTML = error_content;
          $error_container.classList.remove("d-none");
          $error_container.scrollIntoView();
        }
      } else if (data.steps && data.steps.next && data.steps.next[1]) {
        location.href = data.steps.next[1];
      }
    },
    function onError(err) {
      undoFormProcessing($signup_form);
      console.log(err);
    }
  );
})();
