// functions ***************************************************************
// *************************************************************************
function isValidDate(date_value) {
    return date_value instanceof Date && !isNaN(date_value);
}

function formatDate(date) {
    const moment_date = moment(date, 'YYYY-MM-DD');    // required for the input date object
    // const moment_date = moment(date, 'MM/DD/YYYY'); // required for the data-datepicker object
    return moment_date.toDate();
}

function formatDateAlt(date) {
    const moment_date = moment(date, 'MM-DD-YYYY');
    return moment_date.toDate();
}

// date constraint logic ***************************************************
// *************************************************************************
let minDate, maxDate;

$.fn.dataTable.ext.search.push(
    function (settings, data, dataIndex) {
        let date = new Date(formatDateAlt(data[0]));

        // minimum date range
        minDate = $('#sh-min-range').val();
        minDate = new Date(formatDate(minDate));
        if (!isValidDate(minDate)) {
            minDate = null;
        }

        // maximum date range
        maxDate = $('#sh-max-range').val();
        maxDate = new Date(formatDate(maxDate));
        if (!isValidDate(maxDate)) {
            maxDate = null;
        }

        // debugger
        // console.log(minDate, maxDate, date);

        // return data results
        return (minDate === null && maxDate === null) ||
            (minDate === null && date <= maxDate) ||
            (minDate <= date && maxDate === null) ||
            (minDate <= date && date <= maxDate);
    }
);


// register data table *****************************************************
// *************************************************************************
let subscription_history_table = $('#subscription_history_table').DataTable({
    info: false,
    paging: false,
    ordering: true,
    order: [
        [0, 'desc']
    ],
    searching: true,
    fixHeaders: true,
    columns: [
        {
            searchable: true,
            orderable: true
        },
        {
            searchable: false,
            orderable: false
        },
        {
            searchable: false,
            orderable: false
        },
        {
            searchable: false,
            orderable: false
        }
    ],
    language: {
        zeroRecords: "No Subscriptions found within this Date Range."
    }
});

let purchased_resumes_table = $('#purchased_resumes_table').DataTable({
    info: false,
    paging: false,
    ordering: true,
    order: [
        [2, 'desc']
    ],
    searching: true,
    fixHeaders: true,
    columns: [
        {
            searchable: true,
            orderable: true
        },
        {
            searchable: true,
            orderable: true
        },
        {
            searchable: false,
            orderable: true
        },
        {
            searchable: false,
            orderable: false
        }
    ],
    language: {
        zeroRecords: "No Candidate Found"
    }
});


// re-draw table on date input changes *************************************
// *************************************************************************
// subscription history (date range)
$('#sh-min-range, #sh-max-range').on('changeDate change', function () {
    subscription_history_table.draw();
});
// purchase history (search box)
$('#ph-search').on('keyup paste', function () {
    purchased_resumes_table.search(this.value, false, true, true).draw();
});