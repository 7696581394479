// UTILITY FUNCTIONS ***********************************************************
window.debounce = function (func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

// DISABLE FORM ON SUBMISSION **************************************************
window.formProcessing = function (form) {
    const element = form.querySelector("[type=submit]");

    element.disabled = true;
    element.classList.add("processing");
    element.innerHTML = "Processing...";
};

window.undoFormProcessing = function (form) {
    const element = form.querySelector("[type=submit]");

    element.disabled = false;
    element.classList.remove("processing");
    element.innerHTML = "Submit";
};

// NAVBAR BEHAVIORS ************************************************************
window.doTheScroll = function () {
    let state = window.scrollY,
        element = document.getElementsByTagName("header")[0],
        height = element.offsetHeight - 60;

    state < lastScrollTop
        ? element.classList.remove("thin")
        : element.classList.add("thin");
    state < height
        ? element.classList.remove("floatingNav")
        : element.classList.add("floatingNav");

    state < height
        ? document.getElementById("scroll-to-top").classList.add("d-none")
        : document.getElementById("scroll-to-top").classList.remove("d-none");

    lastScrollTop = state <= 0 ? 0 : state; // For Mobile or negative scrolling
};

// MORE SCROLLING BEHAVIOR *****************************************************
let lastScrollTop = 0;
window.addEventListener("scroll", debounce(doTheScroll, 20), false);

window.pageTop = function () {
    window.scrollTo({
        top: document.querySelector("body").offsetTop,
        behavior: "smooth",
    });
};

window.scrollToID = function (id) {
    let element = document.getElementById(id);
    window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
    });
};

// READ MORE *******************************************************************
window.readMore = function (element) {
    if (element.parentNode.classList.contains("less")) {
        element.parentNode.classList.remove("less");
        element.parentNode.classList.add("more");
        element.innerHTML = "Read Less -";
    } else {
        element.parentNode.classList.remove("more");
        element.parentNode.classList.add("less");
        element.innerHTML = "Read More +";
    }
};

// MISC ************************************************************************
window.toggleClass = function (element, tag) {
    element.classList.toggle(tag);
};

window.advancedSearchOptions = function (element) {
    toggleClass(document.getElementById("filter"), "d-block");
    if (element.innerHTML === "Advanced Search") {
        element.innerHTML = "Close";
    } else {
        element.innerHTML = "Advanced Search";
    }
};

window.viewPassword = function (id, field) {
    let password = document.getElementById(field),
        type = password.getAttribute("type") === "password" ? "text" : "password",
        target_id = document.getElementById(id).classList;

    password.setAttribute("type", type);

    if (target_id.contains("fa-eye")) {
        target_id.remove("fa-eye");
        target_id.add("fa-eye-slash");
    } else {
        target_id.remove("fa-eye-slash");
        target_id.add("fa-eye");
    }
};

window.notEmptyFormChecker = function (formTarget) {
    let returnValue = false;
    // check for fields within the form container
    formTarget
        .closest("form")
        .querySelectorAll("input:not([type=hidden]), option:checked, textarea")
        .forEach((formSelector) => {
            if (formSelector.value.length > 0) {
                returnValue = true;
            }
        });

    return returnValue;
};

// adds textarea max-limit/typed count feature on keyup
window.textareaCounter = function (object, limit) {
    let counter = object.parentNode.querySelector(".count"),
        current_count = object.value.length;

    counter.innerHTML = current_count;

    if (current_count >= limit) {
        counter.classList.add("color-red");
        counter.classList.remove("color-yellow");
    } else if (current_count > limit - 10) {
        counter.classList.add("color-yellow");
        counter.classList.remove("color-red");
    } else {
        counter.classList.remove("color-red");
        counter.classList.remove("color-yellow");
    }
};

// Hamburger Menu
const hamburger = document.querySelector(".hamburger");
const updateVH = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
};

function isOverflown(element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

function navbarAdjust() {
    let menuSelector = document.querySelector('.navbar-nav');

    if (isOverflown(menuSelector)) {
        menuSelector.classList.add('overflown');
    } else {
        menuSelector.classList.remove('overflown');
    }
}

hamburger.addEventListener("click", (event) => {
    const body = document.body;
    const toggleClasses = ["nav-open", "overflow-hidden"];
    let menu = event.currentTarget;
    let menu_target = menu.dataset.menuTarget;
    body.dataset.mobileNavOpen = "false";

    toggleClasses.forEach((toggleClass) => body.classList.toggle(toggleClass));

    if (body.classList.contains('nav-open')) {
        body.dataset.mobileNavOpen = "true";
    }

    setTimeout(() => {
        updateVH();
        navbarAdjust();
    }, 500);
});

window.addEventListener('resize', () => {
    const body = document.body;
    if (window.innerWidth > 992) {
        body.classList.remove('nav-open');
        body.classList.remove('overflow-hidden');
        document.querySelector("[data-menu='main-navigation'] .collapse").classList.remove('show');
        body.dataset.mobileNavOpen = "false";
    }

    setTimeout(() => {
        updateVH();
        navbarAdjust();
    }, 500);
});

window.addEventListener('deviceorientation', () => {
    const body = document.body;
    if (window.innerWidth > 992) {
        body.classList.remove('nav-open');
        body.classList.remove('overflow-hidden');
        document.querySelector("[data-menu='main-navigation'] .collapse").classList.remove('show');
        body.dataset.mobileNavOpen = "false";
    }

    setTimeout(() => {
        updateVH();
        navbarAdjust();
    }, 500);
});


// BOOTSTRAP POPOVER ***********************************************************
const popoverTriggerList = document.querySelectorAll(
        '[data-bs-toggle="popover"]'
    ),
    popoverList = [...popoverTriggerList].map(
        (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl)
    );

// JS INPUT MASKING ************************************************************
const mask_routines = {
    card: ($element) => {
        IMask($element, {mask: "0000 0000 0000 0000"});
    },
    cvv: ($element) => {
        IMask($element, {mask: "0000"});
    },
    expiration_date: ($element) => {
        IMask($element, {
            mask: Date,
            pattern: "m/`Y",
            blocks: {
                m: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12,
                    maxLength: 2,
                },
                Y: {
                    mask: IMask.MaskedRange,
                    from: 1900,
                    to: 9999,
                    maxLength: 4,
                },
            },
            format: function (date) {
                let month = date.getMonth() + 1;
                let year = date.getFullYear();

                if (month < 10) month = "0" + month;

                return [month, year].join("/");
            },
            parse: function (str) {
                let monthYearDay = str.split("/");
                return new Date(monthYearDay[1], monthYearDay[0] - 1);
            },
            autofix: true,
            lazy: true,
            overwrite: true,
        });
    },
    phone: ($element) => {
        IMask($element, {
            mask: [
                {
                    mask: "(000) 000-0000"
                },
                {
                    mask: "+{0} (000) 000-0000"
                },
                {
                    mask: "+{00} (000) 000-0000"
                },
                {
                    mask: "+{000} (000) 000-0000"
                }
            ],
            dispatch: function (appended, dynamicMasked) {
                const number = (dynamicMasked.value + appended).replace(/\D/g, "");

                if (number.length <= 10) {
                    return dynamicMasked.compiledMasks[0];
                }
                if (number.length === 11) {
                    return dynamicMasked.compiledMasks[1];
                }
                if (number.length === 12) {
                    return dynamicMasked.compiledMasks[2];
                }

                return dynamicMasked.compiledMasks[3];
            }
        });
    },
    zip: ($element) => {
        IMask($element, {mask: "00000-0000"});
    },
};

const mask = () => {
    document.querySelectorAll("[data-input-mask]").forEach(($element) => {
        const mask_requested = $element.dataset.inputMask;

        if (typeof mask_routines[mask_requested] === "function") {
            mask_routines[mask_requested]($element);
        }
        delete $element.dataset.mask;
    });
};

// Card Detection
let card_number = document.querySelectorAll("[name=card_number]");
if (card_number) {
    card_number.forEach(element => {
        element.addEventListener("keyup", (event) => {
            if (event.currentTarget.value.length === 0) {
                document.querySelectorAll("[name=card_type]").forEach(card_type => {
                    card_type.value = "";
                });
                document.querySelectorAll("[data-card-id]").forEach(card_type => {
                    card_type.innerHTML = "";
                    card_type.classList.add('d-none');
                });
            }
        });
    });

    $("[name=card_number]")
        .detectCard()
        .on("cardChange", function (event, card) {
            let icon,
                icon_support = [
                    "visa",
                    "mastercard",
                    "amex",
                    "discover",
                    "jcb",
                    "diners-club",
                ];

            card_number.forEach(element => {
                if (element.value.length > 0) {
                    icon =
                        jQuery.inArray(card.type, icon_support) === -1
                            ? "<i class='fa-solid fa-credit-card'></i>"
                            : "<i class='fa-brands fa-cc-" + card.type + "'></i>";

                    document.querySelectorAll("[name=card_type]").forEach(card_type => {
                        card_type.value = card.type;
                    });
                    document.querySelectorAll("[data-card-id]").forEach(card_type => {
                        card_type.innerHTML = icon;
                        card_type.classList.remove('d-none');
                    });
                }
            });
        });
}

// GLOBAL EVENT LISTENERS ******************************************************
const registerGlobalEventListeners = () => {
    // shows .clear-search when applicable on element change / keyup
    document.querySelectorAll("form").forEach((element) => {
        ["change", "keyup"].forEach((event_type) => {
            element.addEventListener(event_type, function (event) {
                let formValue = notEmptyFormChecker(element, "form");
                let searchField = element.querySelector("[name=search]");
                let clearResults = element.querySelector(".clear-search");

                if (searchField && formValue) {
                    clearResults.classList.remove("d-none");
                } else if (searchField && !formValue) {
                    clearResults.classList.add("d-none");
                }
            });
        });
    });

    // handles .clear-search logic on click (showing/hiding btn & focusing input)
    document.querySelectorAll(".clear-search").forEach((element) => {
        element.addEventListener("click", function (event) {
            window.location.href = window.location.href.split('?')[0];
        });
    });
};

// INIT ************************************************************************

// js input masking
mask();
// global event listener
registerGlobalEventListeners();

// REPEATABLE FIELDS AND CUSTOM UI *********************************************
["removing", "removed", "adding", "added"].forEach((event_type) => {
    document.addEventListener(`data-repeatable-${event_type}`, (event) => {
        const $element = event.detail.element;
        if (!$element) {
            return;
        }

        switch (event_type) {
            case "removing":
                if (event.detail.label.includes("fuse-selected-")) {
                    // remove the value from the csv field
                    const code = $element.dataset.code;
                    const description = $element.dataset.description;
                    const string_entry = `${code}|${description}`;
                    const $csv_list = event.detail.parent.querySelector(".csv-list");
                    let existing_entries = $csv_list.value.split(";");
                    const index = existing_entries.indexOf(string_entry);
                    if (index > -1) {
                        existing_entries.splice(index, 1);
                    }
                    $csv_list.value = existing_entries.join(";");
                }
                break;
            case "adding":
                if (event.detail.label.includes("fuse-selected-")) {
                    // only add if the source element has a value
                    const $source = event.detail.parent.querySelector(
                        `[data-custom-input="fuse-dropdown"]`
                    );

                    if ($source.value.length < 1) {
                        $element.preventRender = true;
                        return;
                    }

                    // replace the characters we use in parsing
                    $source.value = $source.value.replace(";", ",").replace("|", "/");

                    // update our csv list with new data if it isn't already in there
                    const code = $source.dataset.code || "[NEW-ENTRY]";
                    const description =
                        $source.dataset.description || $source.value || "Unknown";
                    const $csv_list = event.detail.parent.querySelector(".csv-list");
                    let existing_values = $csv_list.value.split(";").map((x) => x.trim());
                    const new_entry_string = `${code}|${description}`.trim();

                    if (existing_values.includes(new_entry_string)) {
                        $element.preventRender = true;
                    } else {
                        existing_values.push(`${code}|${description}`);
                        $csv_list.value = existing_values.join(";");
                        // attach code and description to item being added
                        $element.dataset.code = code;
                        $element.dataset.description = description;
                        let show_codes =
                            $source.dataset.showCodes === "true" ? true : false;
                        if (show_codes) {
                            $element.querySelector(
                                ".label"
                            ).textContent = `${code} - ${description}`;
                        } else {
                            $element.querySelector(".label").textContent = description;
                        }
                    }
                }
                break;
            case "added":
                // handle new unregistered custom form elements
                const $input = event.detail.parent.querySelector(
                    "[data-fuse-collection]"
                );
                if ($input) {
                    $input.value = "";
                }
                setTimeout(() => {
                    window.CustomFormElements.register();
                }, 1000);
                break;
        }
    });
});

/**
 * Register our edit forms as a json array.
 *
 * Note: .edit-form.json is used for editor forms that include repeatable fields.
 */
domReady(() => {
    document.querySelectorAll(".edit-form.json").forEach(($form) => {
        let path = location.protocol + "//" + location.host + location.pathname;

        window.json_form.register(
            $form,
            function beforeSubmit(payload) {
            },
            function onResponse(data) {
                // undo form processing
                undoFormProcessing($form);
                window.location.href = path + "?message_response=success";
            },
            function onError(error) {
                // undo form processing
                undoFormProcessing($form);
                window.location.href = path + "?message_response=error";
                console.log(error);
            }
        );
    });
});

// toggle end date "required" for school and work
domReady(() => {
    document.querySelectorAll("form").forEach((form) => {
        form.addEventListener("change", (event) => {
            // toggle required on school end date
            if (
                event.target.hasAttribute("type") &&
                event.target.getAttribute("type") === "checkbox" &&
                (event.target.getAttribute("name").startsWith("current_school") ||
                    event.target.dataset.saveAs === "current_school")
            ) {
                const $checkbox = event.target;
                const $shared_parent = $checkbox.closest("[data-repeatable-item]");
                if (!$shared_parent) {
                    return;
                }
                const $end_date =
                    $shared_parent.querySelector('[name^="school_end_date"]') ||
                    $shared_parent.querySelector('[data-save-as="end_date"]');
                if (!$end_date) {
                    return;
                }
                if ($checkbox.checked) {
                    $end_date.required = false;
                    $end_date.value = "";
                    $end_date.disabled = true;
                } else {
                    $end_date.required = true;
                    $end_date.disabled = false;
                }
            }

            // toggle required on working end date
            if (
                event.target.hasAttribute("type") &&
                event.target.getAttribute("type") === "checkbox" &&
                event.target.getAttribute("name").startsWith("current_job")
            ) {
                const $checkbox = event.target;
                const $shared_parent = $checkbox.closest("[data-repeatable-item]");
                if (!$shared_parent) {
                    return;
                }
                const $end_date = $shared_parent.querySelector(
                    '[name^="job_end_date"]'
                );
                if (!$end_date) {
                    return;
                }
                if ($checkbox.checked) {
                    $end_date.required = false;
                    $end_date.value = "";
                    $end_date.disabled = true;
                } else {
                    $end_date.required = true;
                    $end_date.disabled = false;
                }
            }
        });
    });
});

window.cancelEdits = function () {
    window.location.href =
        location.protocol + "//" + location.host + location.pathname;
};

/* Auto Complete Detection Functionality */
const observer = new MutationObserver(mutations => {
    let element = mutations[0].target[0];
    if (!element) return;

    if (element.hasAttribute('data-first-loadSubscription')) {
        ["click", "change"].forEach((event_type) => {
            element.addEventListener(event_type, (event) => {
                let parent = element.parentNode;
                let lookup_id = parent.options[parent.selectedIndex].dataset.id;

                parent.parentNode.querySelector('.form-select .selected').innerHTML = parent.options[parent.selectedIndex].innerHTML;

                parent.parentNode.querySelectorAll("option").forEach(option => {
                    option.removeAttribute('selected');
                });

                parent.querySelectorAll('.form-select .select-option').forEach(object => {
                    if (object.classList.contains('active')) {
                        object.classList.remove('active');
                    }
                });
                parent.parentNode.querySelector('.form-select [data-id-for=' + lookup_id + ']').classList.add('active');
            });
        });
    } else {
        element.setAttribute('data-first-loadSubscription', 'true');
    }
});

document.querySelectorAll('[data-custom-input=dropdown]').forEach(element => {
    // Set the select field to be monitored
    observer.observe(element, {
        subtree: true,
        attributes: true
    });
});